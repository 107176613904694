import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import EmojiSwitcher from '../components/EmojiSwitcher';
import LandingCarousel from '../components/LandingCarousel';

import "../stylesheets/Landing.css"

function Landing({handleModalOpen, dataTheme}) {
    const navigate = useNavigate()

    const [currentSlide, setCurrentSlide] = useState(0);

    return (
        <div className='landing-page'>
            <Helmet>
                {/* <title>BokenMap</title> */}
                <meta name='description' content="BokenMap is a tool for travelers to record visited countries and cities. You can add your countries and cities to your map and list, and you can download the image as well as embed the map on other websites." />
                <meta name="keywords" content="travel, map, bokenmap, boken, travels, record, travel diary" />

                <meta name="robots" content="index, follow" />

                <meta property="og:title" content="BokenMap" />
                <meta property="og:description" content="BokenMap is a tool for travelers to record visited countries and cities. You can add your countries and cities to your map and list, and you can download the image as well as embed the map on other websites." />
                {/* 動的OGP画像生成！！！！ */}
                <meta property="og:image" content="https://pbs.twimg.com/media/GdZhWu9bwAAYz91?format=jpg&name=large" />
                <meta property="og:url" content="https://bokenmap.com" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="BokenMap" />
                <meta name="twitter:description" content="BokenMap is a tool for travelers to record visited countries and cities. You can add your countries and cities to your map and list, and you can download the image as well as embed the map on other websites." />
                {/* 動的OGP画像生成！！！！ */}
                <meta name="twitter:image" content="https://pbs.twimg.com/media/GdZhWu9bwAAYz91?format=jpg&name=large" />

                <link rel="icon" href="favicon.ico" type="image/x-icon" />
            </Helmet>


            <div className="landing-top-bar">
                <div className='landing-left'>
                    <h1 className='landing-left-h1'>Map & Show Off Where You've Traveled<span>.</span></h1>
                    <p className='landing-left-p'>BokenMap is a tool for travelers to record visited countries and cities.
                        You can add your countries and cities to your map and list, and you can download the image as well as embed the map on other websites.</p>
                    {/* <p className='landing-left-p'>BokenMap is a map and show off tool for travelers.
                         It shows where you've been, where you're going, and where you're currently.
                        You can add your own countries and cities to your list, and you can also add the countries and cities you've visited.</p> */}
                    <div className='call-to-action-row'>
                        <div className='call-to-create' onClick={handleModalOpen}>Create Profile</div>
                        <div className='call-to-example' onClick={()=>{navigate("/hikaru")}}>Example Profile</div>
                    </div>
                </div>
                <div className="landing-right">
                    <div className='landing-type-row'>
                        <div className='landing-right-image-type'>{currentSlide === 0 ? "Country" : "City"}</div>
                    </div>
                    <div className='right-image-container'>
                        {/* {dataTheme === "dark" ? 
                        <img src="https://pbs.twimg.com/media/GdZhWu9bwAAYz91?format=jpg&name=large" alt="" className='right-image' /> : 
                        <img src='https://pbs.twimg.com/media/GdZl878aMAAJzwF?format=jpg&name=large' alt='' className='right-image' />} */}
                        <LandingCarousel theme={dataTheme} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
                    </div>
                    <div className='landing-data-container'>
                        <div className="landing-data-each">
                            <div className="landing-data-top">{currentSlide === 0 ? 3 : 3}</div>
                            <div className="landing-data-bottom">
                                <div className="landing-data-icon-lived"></div>
                                <div className="landing-data-text">Lived</div>
                            </div>
                        </div>
                        <div className="landing-data-each">
                            <div className="landing-data-top">{currentSlide === 0 ? 23 : 111}</div>
                            <div className="landing-data-bottom">
                                <div className="landing-data-icon-visited"></div>
                                <div className="landing-data-text">Visited</div>
                            </div>
                        </div>
                        <div className="landing-data-each">
                            <div className="landing-data-top">{currentSlide === 0 ? 2 : 3}</div>
                            <div className="landing-data-bottom">
                                <div className="landing-data-icon-transited"></div>
                                <div className="landing-data-text">Transited</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='landing-quote-container'>
                <p>I love traveling.</p>
                <p>I want to record where I have traveled to. I want to share it too.</p>
                <br />
                <p>How should I do it?</p>
                <p>A travel diary? Google Maps? Perhaps.</p>
                <br />
                <p>Or maybe this could be it<span className='landing-quote-dot'></span></p>
            </div>

            <div className='emoji-switch-container'>
                <EmojiSwitcher emojis={["🌍", "🌎", "🌏"]} intervalTime={900} className="emoji-switcher" />
                <div className='emoji-shadow'></div>
            </div>
        </div>
    );
}

export default Landing;