import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import "../stylesheets/Plus.css"
import EditCountryMap from './edit/EditCountryMap';
import { getColorSet } from '../components/getColorSet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCheck } from '@fortawesome/free-solid-svg-icons';

function Plus({user, setUser, theme, mapColor, setMapColor}) {

    const [plus, setPlus] = useState(user.user.plus)


    // useEffect(() => { 
    //   fetch(`${process.env.REACT_APP_BACK_DOMAIN}/users`, {method: 'POST', credentials: "include", headers: {'Content-Type': 'application/json',},
    //     body: JSON.stringify({handle: user.user.handle}),
    //   }).then((response) => {
    //       if (!response.ok) {
    //         throw new Error(`HTTP error! Status: ${response.status}`);
    //       }
    //       return response;
    //     }).then((result) => {
    //     }).catch((err) => console.error(err));
    // }, [])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/billing/check`, {method: "POST", credentials: "include", headers: { "Content-Type": "application/json" },
            body: JSON.stringify({stripeCustomerId: user.user.stripeCustomerId})
        }).then(res => res.json())
        .then(data => {
          console.log(data.data);
          
          if(data.data.length === 0){
            fetch(`${process.env.REACT_APP_BACK_DOMAIN}/users`, {method: 'POST', credentials: "include", headers: {'Content-Type': 'application/json',},
              body: JSON.stringify({handle: user.user.handle}),
            }).then((response) => {
                if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response;
            }).then((result) => {
                setUser({...user, user: {...user.user, plus: false}})
                setPlus(false)
            }).catch((err) => console.error(err));
          }else{
            const isActive = data.data[0].status === "active" ? true : false
            if(user.user.plus === isActive){
              return
            }else{
              fetch(`${process.env.REACT_APP_BACK_DOMAIN}/users`, {method: 'POST', credentials: "include", headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({handle: user.user.handle}),
              }).then((response) => {
                  if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                  }
                  return response;
                }).then((result) => {
                  setUser({...user, user: {...user.user, plus: isActive}})
                  setPlus(isActive)
                }).catch((err) => console.error(err));
            }
          }
        }).catch(err => console.log(err));
    }, []);







    const selectMapColor = (color) => {
        setMapColor(color)
        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/api/plus/color`, {method: 'POST', credentials: "include", headers: {'Content-Type': 'application/json',},
          body: JSON.stringify({color, user}),
        }).then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response;
          }).then((result) => {})
          .catch((err) => console.error(err));
    }

    const userColors = getColorSet(mapColor)

    const countryArr = [
        {
          "shortName": "SG",
          "name": "Singapore",
          "continent": "Asia",
          "status": "1"
        },
        {
          "shortName": "US",
          "name": "United States",
          "continent": "North America",
          "status": "1"
        },
        {
          "shortName": "MY",
          "name": "Malaysia",
          "continent": "Asia",
          "status": "2"
        },
        {
          "shortName": "ES",
          "name": "Spain",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "FR",
          "name": "France",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "PT",
          "name": "Portugal",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "LK",
          "name": "Sri Lanka",
          "continent": "Asia",
          "status": "3"
        },
        {
          "shortName": "TH",
          "name": "Thailand",
          "continent": "Asia",
          "status": "2"
        },
        {
          "shortName": "VN",
          "name": "Vietnam",
          "continent": "Asia",
          "status": "2"
        },
        {
          "shortName": "ID",
          "name": "Indonesia",
          "continent": "Asia",
          "status": "2"
        },
        {
          "shortName": "MV",
          "name": "Maldives",
          "continent": "Asia",
          "status": "2"
        },
        {
          "shortName": "AU",
          "name": "Australia",
          "continent": "Oceania",
          "status": "2"
        },
        {
          "shortName": "TW",
          "name": "Taiwan",
          "continent": "Asia",
          "status": "3"
        },
        {
          "shortName": "CR",
          "name": "Costa Rica",
          "continent": "North America",
          "status": "2"
        },
        {
          "shortName": "PR",
          "name": "Puerto Rico",
          "continent": "North America",
          "status": "2"
        },
        {
          "shortName": "GB",
          "name": "United Kingdom",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "CH",
          "name": "Switzerland",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "IT",
          "name": "Italy",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "AT",
          "name": "Austria",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "DE",
          "name": "Germany",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "LU",
          "name": "Luxembourg",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "MC",
          "name": "Monaco",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "JP",
          "name": "Japan",
          "continent": "Asia",
          "status": "1"
        },
        {
          "shortName": "VA",
          "name": "Vatican City",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "BE",
          "name": "Belgium",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "QA",
          "name": "Qatar",
          "continent": "Asia",
          "status": "2"
        },
        {
          "shortName": "NL",
          "name": "Netherlands",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "CA",
          "name": "Canada",
          "continent": "North America",
          "status": "2"
        }
      ]

    return (
        <div className='dashboard-right'>
            <Helmet>
                <title>Plus | BokenMap</title>
            </Helmet>
            <h1>Plus</h1>

            {plus && (
                <div>
                    <h2>Color Theme</h2>
                    <div style={{display: "flex"}}>
                        <div className='color-option-container'>
                            <div className='color-option option-green' onClick={()=>selectMapColor("green")}></div>
                            {mapColor === "green" && <FontAwesomeIcon icon={faCircleCheck} className='color-option-check' />}
                        </div>
                        <div className='color-option-container'>
                            <div className='color-option option-blue' onClick={()=>selectMapColor("blue")}></div>
                            {mapColor === "blue" && <FontAwesomeIcon icon={faCircleCheck} className='color-option-check' />}
                        </div>
                        <div className='color-option-container'>
                            <div className='color-option option-red' onClick={()=>selectMapColor("red")}></div>
                            {mapColor === "red" && <FontAwesomeIcon icon={faCircleCheck} className='color-option-check' />}
                        </div>
                        <div className='color-option-container'>
                            <div className='color-option option-purple' onClick={()=>selectMapColor("purple")}></div>
                            {mapColor === "purple" && <FontAwesomeIcon icon={faCircleCheck} className='color-option-check' />}
                        </div>
                        <div className='color-option-container'>
                            <div className='color-option option-brown' onClick={()=>selectMapColor("brown")}></div>
                            {mapColor === "brown" && <FontAwesomeIcon icon={faCircleCheck} className='color-option-check' />}
                        </div>
                    </div>
                    {/* <CountryMap homeUser={null} homeColors={mapColor} /> */}
                    <div className='plus-map-container' style={{width: "100%", marginTop: "20px"}}>
                        <EditCountryMap userColors={userColors} dataTheme={theme} countryArr={countryArr} />
                    </div>


                    <h2 style={{marginTop: "50px"}}>Subscription Info</h2>
                    <p style={{display: "flex", alignItems: "center"}}>Status: <span className='dashboard-status-icon'></span>Active</p>
                    <p>The subscription will be renewed automatically, and you can cancel at any time. Manage your subscription from the billing portal below.</p>

                    <div className='dashboard-manage-subscription-container'>
                      <div className='dashboard-manage-subscription-button' onClick={()=>{window.open(`${process.env.REACT_APP_STRIPE_PORTAL_LINK}?prefilled_email=${user.user ? user.user.email : null}`, "_blank")}}>Manage Subscription</div>
                    </div>
                </div>
            )}

            {!plus && (
                <div>
                  <h2>Change map color by sucbscribing to Plus.</h2>
                  <div className='dashboard-plus-button-container'>
                    <Link to="/pricing" className="dashboard-plus-button">See Plans</Link>
                  </div>

                    {/* <div className='plus-pricing-container'>
                        <div className='pricing-each'>
                            <div className='price-title'>Free</div>
                            <div className='price-text'><span className='price-span'>$0</span>/month</div>
                            <ul className="feature-list"></ul>
                            <div className='current-button'>Current</div>
                        </div>
                        <div className='pricing-each'>
                            <div className='price-title'>Plus</div>
                            <div className='price-text'><span className='price-span'>$3</span>/month</div>
                            <div className='feature-list'>
                              <FontAwesomeIcon icon={faCheck} className='feature-list-icon' />
                              <span className='feature-list-text'>More Colors</span>
                            </div>
                            <div onClick={clickSubscribe} className='subscribe-button'>Subscribe</div>
                        </div>
                    </div> */}


                    {/* <h2>Plus Features</h2> */}
                    {/* <h3>1.More Colors</h3> */}
                    {/* <p>You can choose from a variety of colors to personalize your map.</p> */}
                </div>
                // <Link to="/pricing">Pricing</Link>
            )}


            {/* <div className='settings-each'>
                <h2>Monospace Font</h2>
                <p style={{marginTop: "8px", fontSize: "14px"}}>This theme is set to the browser on your local device. Other viewers of your profile would most likely see it in light mode.</p>
                <div className='settings-each-content'>
                    <p className='settings-each-toggle-label' style={{marginRight: "13px"}}>Regular</p>
                    <ThemeToggle className="theme-toggle"></ThemeToggle>
                    <p className='settings-each-toggle-label' style={{marginLeft: "-12px"}}>Monospace</p>
                </div>
            </div> */}

        </div>
    );
}

export default Plus;